import React from 'react';
import Guideness from "./Component/Guideness";
import Features from "./Component/Features";
import Reviews from "./Component/Reviews";
import Faq from "./Component/Faq";

const CommonSections = ({ 
    guidenessProps = {},
    featuresProps = {},
    reviewsProps = {},
    faqProps = {},
}) => {
    return (
        <>
            {guidenessProps && <Guideness {...guidenessProps}/>}
            {featuresProps && <Features {...featuresProps}  />}
            {reviewsProps && <Reviews {...reviewsProps}/>}
            {faqProps && <Faq {...faqProps} />}
        </>
    );
};

export default CommonSections;