import React from "react";

const FeatureItem = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="bg-blue-500 rounded-full p-3 inline-block mb-4">
      {icon}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Features = ({ 
  title,
  subtitle,
  features
}) => {
  return (
    <div className="container mx-auto px-4 py-8 mt-12 mb-12">
      <h1 className="text-4xl font-bold text-center mb-2">{title}</h1>
      <p className="text-center text-gray-600 mb-8 mt-4">{subtitle}</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <FeatureItem
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;