// HeaderComponent.js
import React from 'react';
import Navigation from "./Component/Navigation";  // 确保路径正确

const HeaderComponent = ({ h1Content, tagline }) => {
    const headerStyle = {
        textAlign: 'center',
        padding: '20px 0'
    };

    const h1Style = {
        fontSize: '2.5em',
        color: '#333',
        marginBottom: '10px'
    };

    const taglineStyle = {
        fontSize: '1.2em',
        color: '#666',
        fontWeight: '300'
    };

    return (
        <>
            <Navigation />
            <header style={headerStyle}>
                <h1 style={h1Style}>{h1Content}</h1>
                <p style={taglineStyle}>{tagline}</p>
            </header>
        </>
    );
};

export default HeaderComponent;