import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, canonical, schema, keywords }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    {keywords && <meta name="keywords" content={keywords} />}
    {canonical && <link rel="canonical" href={canonical} />}
    {schema && (
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    )}
    {/* Google Analytics */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-8TVJE78J3P"></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-8TVJE78J3P');
      `}
    </script>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4270301326382732"
     crossorigin="anonymous"></script>
  </Helmet>
);

export default SEO;