import React, { useState } from 'react';
import axios from 'axios/dist/axios';

export default function KuaiShouDownloader() {
  const [url, setUrl] = useState('');
  const [videoInfo, setVideoInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchVideoInfo = async () => {
    setLoading(true);
    setError('');
    setVideoInfo(null);
  
    try {
      const response = await axios.get(`/api/kuaishou?url=${encodeURIComponent(url)}`);
      setVideoInfo(response.data);
    } catch (err) {
      console.error('Error:', err);
      setError(err.response?.data?.error || 'Failed to fetch video info');
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-2xl font-bold mb-4 text-center">Kuaishou Video Downloader</h1>
      <div className="mb-4">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter Kuaishou video link"
          className="w-full px-3 py-2 border rounded-md"
        />
      </div>
      <button
        onClick={fetchVideoInfo}
        disabled={loading}
        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 disabled:bg-gray-400"
      >
        {loading ? 'Fetching...' : 'Get Video Info'}
      </button>
      {error && <p className="text-red-500 mt-4">{error}</p>}
      {videoInfo && (
        <div className="mt-6">
          <h2 className="text-xl font-bold mb-2">Download Options:</h2>
          <div className="flex justify-between">
            <button
              onClick={() => downloadFile(videoInfo.poster, 'kuaishou_cover.jpg')}
              className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition duration-300"
            >
              Download Cover
            </button>
            <button
              onClick={() => downloadFile(videoInfo.playUrl, 'kuaishou_video.mp4')}
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Download Video
            </button>
          </div>
        </div>
      )}
    </div>
  );
}