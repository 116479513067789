import React from "react";

const Guideness = ({ title, subtitle, steps }) => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8 mt-12 mb-12">
      <h1 className="text-4xl font-bold text-center mb-4">{title}</h1>
      <p className="text-center text-gray-600 mb-12">{subtitle}</p>

      <div>
        {steps.map((step, index) => (
          <div key={index} className={`flex items-start bg-gray-100 p-6 rounded-lg ${index < steps.length - 1 ? 'mb-8' : ''}`}>
            <div className="flex-shrink-0 w-12 h-12 bg-blue-500 text-white rounded-lg flex items-center justify-center text-xl font-bold mr-4">
              {step.number}
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-2">{step.title}</h2>
              <p className="text-gray-600">
                {step.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Guideness;