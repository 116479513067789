import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* 特性部分 */}
          <div>
            <h3 className="font-bold mb-4">Reviews</h3>
            <ul className="space-y-2 list-none p-0">
              <li>
                <a href="https://www.aianime-generator.org" className="text-white no-underline hover:text-gray-300">
                  AI Anime Generator
                </a>
              </li>
            </ul>
          </div>
          {/* 支持部分 */}
          <div>
            <h3 className="font-bold mb-4">Support</h3>
            <ul className="space-y-2 list-none p-0">
            <li>
                <a href="/youtube/download-mp3" className="text-white no-underline hover:text-gray-300">
                  Download YouTube MP3
                </a>
            </li>
            <li>
                <a href="/youtube/download-mp4" className="text-white no-underline hover:text-gray-300">
                  Download YouTube Video
                </a>
            </li>
              <li>
                <a href="/youtube/download-shorts" className="text-white no-underline hover:text-gray-300">
                  Download YouTube Shorts
                </a>
              </li>
              <li>
                <a href="/youtube/download-subtitles" className="text-white no-underline hover:text-gray-300">
                  Download YouTube Subtitles
                </a>
              </li>
            </ul>
          </div>
          {/* 法律和博客部分 */}
          <div>
            <h3 className="font-bold mb-4">Legal & Blogs</h3>
            <ul className="space-y-2 list-none p-0">
            <li>
                <a href="/blog" className="text-white no-underline hover:text-gray-300">
                  Blog
                </a>
              </li>
              <li>
                <a href="/privacy-policy" className="text-white no-underline hover:text-gray-300">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms-of-use" className="text-white no-underline hover:text-gray-300">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
          {/* 联系信息部分 */}
          <div>
            <div className="flex items-start mb-4">
              <span className="mr-2 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
              </span>
              <p>218 Azalea Street<br />Florence, Oregon 97439<br />USA</p>
            </div>
            <div className="flex items-center">
              <span className="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </span>
              <p>support@yttools.cc</p>
            </div>
          </div>
        </div>
        <hr className="my-8 border-gray-700" />
        <p className="text-center text-sm">© Copyright 2024. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

