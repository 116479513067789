import React from "react";

const ReviewCard = ({ content, name, title }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="text-gray-600 mb-4">{content}</p>
    <h3 className="font-semibold">{name}</h3>
    <p className="text-sm text-gray-500">{title}</p>
  </div>
);

const Reviews = ({ title, reviews }) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>
    </div>
  );
};

export default Reviews;