import React from "react";

const Navigation = () => {
  return (
    <div className="py-4 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
      <nav>
        <div className="container px-4 mx-auto">
          <a 
            href="/youtube/download-mp3" 
            className="text-2xl font-bold text-black dark:text-white hover:opacity-80 transition-opacity no-underline"
          >
            Home
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;