import React, { Fragment } from 'react';
import SEO from "../../components/public/Component/SEO";
import HeaderComponent from "../../components/public/HeaderComponent";
import CommonSections from "../../components/public/CommonSections";
import Footer from '../../components/public/Component/Footer';
import ComingSoon from '../../components/public/Component/ComingSoon';
// import Subtitlesdownload from "../components/special/Subtitlesdownload";


const DownloadMP4 = () => {
/*   const seoData = {
        title: "YouTube Converter subtitles Online | High Quality Audio - yttools",
        description: "Convert YouTube videos to subtitles easily. Our free online converter offers fast, high-quality audio downloads. Click to convert your favorite videos on any device!",
        canonical: "https://www.yttools.cc/youtube-to-subtitles",
        schema: {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "yttools - YouTube to subtitles Converter",
            "url": "https://www.yttools.cc/youtube-to-subtitles",
            "description": "Convert YouTube videos to subtitles easily with yttools. Fast, free, and high-quality audio downloads for your favorite YouTube videos.",
            "applicationCategory": "MultimediaApplication",
            "operatingSystem": "Web",
            "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
            },
            "featureList": [
                "High-quality subtitles conversion",
                "Fast download speeds",
                "No software installation required",
                "Compatible with all devices"
            ]
        },
    };
    
*/

    const h1Content = "YouTube Converter subtitles Online - Free & High Quality";

    const tagline = "Transform Your Favorite YouTube Videos to High-Quality subtitles Audio";

/*
    const guidenessContent = {
        title: "How subtitles Converter Works",
        subtitle: "Convert your favorite YouTube videos to subtitles audio files with ease using our simple and efficient converter.",
        steps: [
            {
                number: 1,
                title: "Paste YouTube URL:",
                description: "Start by copying the URL of the YouTube video you want to convert. Paste this URL into the input field on our converter page."
            },
            {
                number: 2,
                title: "Select Output Format:",
                description: "Choose subtitles as your desired output format. Our converter supports high-quality audio extraction to ensure the best sound experience."
            },
            {
                number: 3,
                title: "Convert and Download:",
                description: "Click the 'Convert' button to start the process. Once conversion is complete, download your subtitles file directly to your device and enjoy your audio anywhere, anytime."
            }
        ]
    };

    const featuresContent = {
        title: "YouTube to subtitles Converter: Transform Videos Into Audio Files",
        subtitle: "Explore the unique features of our YouTube to subtitles Converter, designed to simplify your audio extraction process and enhance your music library.",
        features: [
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" /></svg>,
                title: "Easy Conversion",
                description: "Simply paste your YouTube URL and get high-quality subtitles files with just one click."
            },
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>,
                title: "Fast Processing",
                description: "Experience rapid conversion speeds, allowing you to download your audio files in seconds."
            },
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>,
                title: "High Quality Audio",
                description: "Our converter maintains the best possible audio quality during the extraction process."
            },
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg>,
                title: "No Software Installation",
                description: "Use our online converter directly in your browser without the need to download any software."
            },
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg>,
                title: "Secure Conversion",
                description: "Our tool ensures your privacy and security during the entire conversion process."
            },
            {
                icon: <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" /></svg>,
                title: "Free to Use",
                description: "Enjoy unlimited conversions without any cost or hidden fees."
            }
        ]
    };
    const faqList = [
        {
            question: "How do I use the YouTube converter subtitles online?",
            answer: "To use our YouTube converter subtitles online, simply paste the YouTube video URL into the converter, select your desired audio quality, and click the convert button. Our tool will process your request and provide a download link for the subtitles file.",
        },
        {
            question: "Is this YouTube to subtitles converter free to use?",
            answer: "Yes, our YouTube to subtitles conversion tool is completely free. You can convert and download your favorite YouTube videos to high quality subtitles audio without any cost.",
        },
        {
            question: "What is the maximum video length for conversion?",
            answer: "Our free YouTube to subtitles converter can handle videos up to 2 hours in length. For longer videos, you may need to split them into smaller parts before conversion.",
        },
        {
            question: "Can I use this converter on my mobile device?",
            answer: "Absolutely! Our YouTube to subtitles converter is fully compatible with mobile devices. You can easily convert YouTube videos to subtitles files on your smartphone or tablet.",
        },
        {
            question: "What audio quality can I expect from the subtitles files?",
            answer: "We offer high quality audio conversion. You can choose between 128kbps, 192kbps, and 320kbps subtitles formats for the best sound quality.",
        },
        {
            question: "How long does the conversion process usually take?",
            answer: "The conversion of YouTube videos to subtitles files usually takes just a few moments. However, the exact time can depend on the length of the video and your internet connection speed.",
        },
    ];
    const reviews = {
        title: "Client Praise for YouTube to subtitles Converter",
        reviews: [
            {
                content: "This YouTube to subtitles converter is a game-changer for my podcast production. I can easily extract audio from YouTube interviews and integrate them into my episodes.",
                name: "Sarah Johnson",
                title: "Podcast Host"
            },
            {
                content: "As a music teacher, I often need to create audio files from YouTube tutorials. This tool makes it incredibly simple and fast!",
                name: "Michael Chen",
                title: "Music Educator"
            },
            {
                content: "I love how user-friendly this converter is. It's perfect for creating my workout playlists from YouTube music videos.",
                name: "Emily Rodriguez",
                title: "Fitness Enthusiast"
            },
            {
                content: "This tool has streamlined my workflow for creating language learning materials. I can quickly extract audio from native speaker videos on YouTube.",
                name: "David Kim",
                title: "Language Learning Content Creator"
            },
            {
                content: "As a DJ, I'm always looking for new tracks. This converter helps me sample sounds from obscure YouTube videos with ease.",
                name: "Alex Thompson",
                title: "Professional DJ"
            },
            {
                content: "For my online music theory courses, I often need audio examples. This YouTube to subtitles converter is a lifesaver - quick, easy, and high-quality results!",
                name: "Laura Martinez",
                title: "Online Music Instructor"
            }
        ]
    };    
    
    */

    return (
        <Fragment>
            
    {/*            <SEO {...seoData}   */}
            <HeaderComponent h1Content={h1Content} tagline={tagline} />
    {/*            <MP4download />    */}
{/*             <CommonSections 
                guidenessProps={guidenessContent}
                featuresProps={featuresContent}
                reviewsProps={reviews}
                faqProps={{
                    faqList: faqList,
                    title: "YouTube Converter subtitles Online FAQ",
                    subtitle: "CONVERT WITH EASE",
                }}
            />
*/}
            <ComingSoon />
            <Footer />
        </Fragment>
    );
}

export default DownloadMP4;