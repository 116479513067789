import React, {Fragment} from 'react';
import Navigation from "../components/public/Component/Navigation";
import ComingSoon from "../components/public/Component/ComingSoon";


const Blog = () => {
    return (
        <Fragment>
            <Navigation />
			<ComingSoon />
        </Fragment>
    );
}

export default Blog;
