import React from "react";

const Faq = ({ title, subtitle, faqList }) => {
  return (
    <section className="ezy__Faq py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white">
      <div className="container px-16 md:px-8 lg:px-28">
        <div className="grid grid-cols-12">
          <div className="col-span-12 text-center sm:text-start">
            <h2 className="font-bold text-[35px] leading-[45px] md:text-[50px] md:leading-[70px]">
              {title}
            </h2>
            <p className="text-sm leading-7 tracking-[2px] opacity-90 sm:ml-6">
              {subtitle}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 text-center md:text-start gap-6 mt-12">
          {faqList.map((faq, i) => (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 sm:pr-6 lg:pr-12"
              key={i}
            >
              <div>
                <h3 className="text-[29px] leading-none">{faq.question}</h3>
                <p className="text-[15px] leading-6 font-light tracking-[1px] opacity-70 mt-4 md:mt-6">
                  {faq.answer}
                </p>
                {faq.link && (
                  <a href={faq.link} className="text-blue-500 hover:underline mt-2 inline-block">
                    Learn more
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;