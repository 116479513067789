import React from "react";

const ComingSoon = () => {
  return (
    <section className="h-screen w-full bg-white flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-black text-6xl md:text-8xl font-bold tracking-wider">
          COMING SOON
        </h1>
      </div>
    </section>
  );
};

export default ComingSoon;

