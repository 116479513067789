import React, { Fragment } from 'react';
import HeaderComponent from "../components/public/HeaderComponent";
import Footer from '../components/public/Component/Footer';

const TermsofUse = () => {
    return (
        <Fragment>
            <HeaderComponent />
            <div className="px-4 md:px-8 lg:px-16 py-8 max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-6">Terms of Use</h1>
                <p className="text-sm text-gray-600 mb-6">Last updated: September 17, 2024</p>

                <h2 className="text-3xl font-semibold mb-4">1. Acceptance of Terms</h2>
                <p className="mb-4">By accessing and using yttools.cc (our "Service"), you accept and agree to be bound by these Terms of Use. If you do not agree to abide by these Terms, please do not use our Service.</p>

                <h2 className="text-3xl font-semibold mb-4">2. Definitions</h2>
                <ul className="list-disc pl-6 mb-6 space-y-2">
                    <li><strong>Service</strong> refers to the yttools.cc website.</li>
                    <li><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
                    <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this agreement) refers to yttools.cc.</li>
                    <li><strong>Country</strong> refers to: California, United States</li>
                    <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                    <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                </ul>

                <h2 className="text-3xl font-semibold mb-4">3. Content</h2>
                <p className="mb-4">Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material. You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
                <p className="mb-4">By posting Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.</p>
                <p className="mb-4">We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>

                <h2 className="text-3xl font-semibold mb-4">4. Copyright Policy</h2>
                <p className="mb-4">We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights of any person or entity.</p>
                <p className="mb-4">If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to support@yttools.cc, with the subject line: "Copyright Infringement" and include in your claim a detailed description of the alleged Infringement.</p>

                <h2 className="text-3xl font-semibold mb-4">5. Intellectual Property</h2>
                <p className="mb-4">The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of yttools.cc and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of yttools.cc.</p>

                <h2 className="text-3xl font-semibold mb-4">6. Links To Other Web Sites</h2>
                <p className="mb-4">Our Service may contain links to third party web sites or services that are not owned or controlled by yttools.cc.</p>
                <p className="mb-4">yttools.cc has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>

                <h2 className="text-3xl font-semibold mb-4">7. Termination</h2>
                <p className="mb-4">We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                <p className="mb-4">All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                <h2 className="text-3xl font-semibold mb-4">8. Indemnification</h2>
                <p className="mb-4">You agree to defend, indemnify and hold harmless yttools.cc and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.</p>

                <h2 className="text-3xl font-semibold mb-4">9. Limitation Of Liability</h2>
                <p className="mb-4">In no event shall yttools.cc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h2 className="text-3xl font-semibold mb-4">10. Disclaimer</h2>
                <p className="mb-4">Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

                <h2 className="text-3xl font-semibold mb-4">11. Governing Law</h2>
                <p className="mb-4">These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.</p>
                <p className="mb-4">Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>

                <h2 className="text-3xl font-semibold mb-4">12. Changes</h2>
                <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                <p className="mb-4">By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

                <h2 className="text-3xl font-semibold mb-4">13. Contact Us</h2>
                <p className="mb-4">If you have any questions about these Terms, please contact us at support@yttools.cc.</p>
            </div>
            <Footer />
        </Fragment>
    );
}

export default TermsofUse;