import React, { useState, useEffect, useRef } from "react";

const MP3download = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
    disableButton();
  };

  const disableButton = () => {
    if (iframeRef.current) {
      try {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        const button = iframeDocument.querySelector('.MuiIconButton-root');
        if (button) {
          button.style.opacity = '0.5';
          button.style.pointerEvents = 'none';
          button.setAttribute('disabled', 'true');
          button.onclick = null;
        }
      } catch (error) {
        console.error("Error disabling button:", error);
      }
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'iframeLoaded') {
        disableButton();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const visibleArea = {
    top: 120,
    right: 0,
    bottom: 3700,
    left: 0,
    width: 800,
    height: 240,
  };

  return (
    <div className="w-full flex flex-col items-center mt-8">
      {!iframeLoaded && (
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-gray-600">Loading...</p>
        </div>
      )}
      <div 
        style={{ 
          width: `${visibleArea.width}px`,
          height: `${visibleArea.height}px`, 
          overflow: "hidden",
          position: "relative",
        }}
      >
        <iframe
          ref={iframeRef}
          src="https://y2mate.nu/04dG/"
          width={`${visibleArea.width + visibleArea.left + visibleArea.right}px`}
          height={`${visibleArea.height + visibleArea.top + visibleArea.bottom}px`}
          style={{
            border: "none",
            position: "absolute",
            top: `-${visibleArea.top}px`,
            left: `-${visibleArea.left}px`,
            clipPath: `inset(${visibleArea.top}px ${visibleArea.right}px ${visibleArea.bottom}px ${visibleArea.left}px)`,
            pointerEvents: "auto",
          }}
          onLoad={handleIframeLoad}
          title="YouTube to MP3 Converter"
        />
      </div>
      <p className="mt-4 text-xs text-gray-400 text-center">
        Service provided by y2mate.nu Please comply with their terms of use and copyright regulations.
      </p>
    </div>
  );
};

export default MP3download;