import React from "react";
import { Routes, Route } from "react-router-dom";

// 使用 require.context 动态导入所有页面组件
const pageComponents = {};
const requireComponent = require.context('./pages', true, /\.js$/);

requireComponent.keys().forEach((fileName) => {
  // 排除 api 目录和非页面组件
  if (!fileName.includes('/api/') && !fileName.includes('Functional-Template')) {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
    pageComponents[componentName] = componentConfig.default || componentConfig;
  }
});

// 路由配置对象
const routeConfig = [
  { path: "/", componentName: "download-mp3" },
  { path: "/youtube/download-mp3", componentName: "download-mp3" },
  { path: "/youtube/download-mp4", componentName: "download-mp4" },
  { path: "/youtube/download-shorts", componentName: "download-shorts" },
  { path: "/youtube/download-subtitles", componentName: "download-subtitles" },
  { path: "/blog", componentName: "blog" },
  { path: "/privacy-policy", componentName: "privacy-policy" },
  { path: "/terms-of-use", componentName: "terms-of-use" },
  { path: "/kuaishou/download-mp4", componentName: "kuaishou-download-mp4" },
];

const AppRoutes = () => {
  return (
    <Routes>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            pageComponents[route.componentName] 
              ? React.createElement(pageComponents[route.componentName])
              : <div>页面不存在</div>
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;